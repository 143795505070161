// base
import React from 'react';
import ReactHelmet from 'react-helmet';
import { FavIcon, LogoIcon } from '../../../utils';

interface HeadBaseProps {
  metas?: any;
  noindex?: boolean;
}

const HeadTemplate = () => {
  return (
    <>
      <meta charSet='utf-8' />
      <meta
        name='viewport'
        content='width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
      />

      {/* material-uiのため */}
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
      />

      {/* 各種メタ情報 */}
      <meta
        name='Keywords'
        content='トライアングル,tryangle,ハンドメイド,SNS,コミュニティ,アプリ,croccha'
      />

      <link rel='apple-touch-icon' href={LogoIcon} />
      <link rel='shortcut icon' href={FavIcon} />
      <link rel='icon' href={LogoIcon} />
    </>
  );
};

const HeadBase: React.FC<HeadBaseProps> = ({ metas, noindex = false }) => {
  if (!metas) {
    return (
      <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
        <HeadTemplate />
        {noindex && <meta name='robots' content='noindex' />}
      </ReactHelmet>
    );
  }
  return (
    <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
      <HeadTemplate />
      {noindex && <meta name='robots' content='noindex' />}
      <title>{metas.title}</title>
      <meta name='description' content={metas.description} />
      {/* <meta property='fb:app_id' content={'354037938752333'} /> */}
      <meta name='og:locale' content='ja-JP' />
      <meta property='og:type' content={metas.openGraph.type} />
      <meta property='og:url' content={metas.openGraph.url} />
      <meta property='og:title' content={metas.openGraph.title} />
      <meta property='og:description' content={metas.openGraph.description} />
      <meta property='og:image' content={metas.openGraph.images[0].url} />
      <meta
        property='og:image:width'
        content={metas.openGraph.images[0].width}
      />
      <meta
        property='og:image:height'
        content={metas.openGraph.images[0].height}
      />
      <meta property='og:image:alt' content={metas.openGraph.images[0].alt} />
      <meta property='og:site_neme' content={metas.openGraph.site_name} />
    </ReactHelmet>
  );
};

export default HeadBase;
