import * as React from 'react';
import styled from 'styled-components';
import { headingFontSize, breakpoint } from '../../theme';

interface SectionTitleProp {
  title: string;
  span: string;
  className?: string;
}

const SectionTitle: React.FC<SectionTitleProp> = ({
  title,
  span,
  className
}) => (
  <Wrap className={className}>
    <Title>{title}</Title>
    <span>{span}</span>
  </Wrap>
);

export default SectionTitle;

const Wrap = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-size: ${headingFontSize.xxLarge};
  @media ${breakpoint.sp} {
    font-size: 41px;
  }
  + span {
    display: block;
    font-size: ${headingFontSize.medium};
    font-weight: bold;
    color: #a2a1a1;
  }
`;
