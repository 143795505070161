import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import LazyLoadImage from '../components/atoms/LazyLoadImage';
import PageTitle from '../components/atoms/PageTitle';
import SectionTitle from '../components/atoms/SectionTitle';
import React from 'react';
import styled from 'styled-components';
import {
  triangleStyle,
  bgColor,
  breakpoint,
  mainColor,
} from '../components/theme';
import {
  CompanyPc,
  CompanySp,
  CROCCHA_API,
  SymbolIcon,
  MembersAllImg,
  MetaDesc,
  MetaOGP,
  SFujiwaraImg,
  Tel,
} from '../utils';
import useMedia from '../customHooks/userMedia';
import HeadBase from '../components/molecules/HeadBase';

const CompanyPage = () => {
  const isMobile = useMedia('(max-width: 767px)');
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: '会社情報 | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/company/`,
            title: '会社情報 | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      {/* アイキャッチ */}
      <PageTitle
        url={isMobile ? CompanySp : CompanyPc}
        title='Company'
        span='会社情報'
      />

      {/* ミッション */}
      <Section>
        <SectionTitle title='Philosophy' span='企業理念' />
        <H2>Vision</H2>
        <Vision>
          作りたいと思ったものを
          <br />
          便利に作れる世界を創造する
        </Vision>
      </Section>
      <BgSection>
        <H2>Value</H2>
        <ValueList>
          <ValueItem>
            <Value>Do try</Value>
            <p>
              楽しもう、
              <br />
              挑戦しよう、
              <br />
              成長しよう
            </p>
          </ValueItem>
          <ValueItem>
            <Value>Be integrity</Value>
            <p>
              誠実であれ、
              <br />
              プロフェッショナルであれ
            </p>
          </ValueItem>
          <ValueItem>
            <Value>By Simple</Value>
            <p>
              ロジカルに、
              <br />
              効率的に考えよう
            </p>
          </ValueItem>
        </ValueList>
      </BgSection>

      {/* 会社概要 */}
      <Section>
        <SectionTitle title='Company profile' span='会社概要' />
        <Dl>
          <dt>社名</dt>
          <dd>tryangle株式会社(トライアングル株式会社)</dd>

          <dt>設立</dt>
          <dd>2018年2月15日</dd>

          <dt>事業内容</dt>
          <dd>
            1. ハンドメイド事業
            <br />
            <CrocchaService>ハンドメイドSNSアプリ 「croccha」</CrocchaService>
            <br />
            <CrocchaService>
              ハンドメイドEC shop 「croccha shop」
            </CrocchaService>
            <br />
            <CrocchaService>
              イベント企画・運営・コンサルティング
            </CrocchaService>
            <br />
            2. ITサービス開発事業 <br />
            3. デジタルマーケティング事業
          </dd>

          <dt>代表取締役</dt>
          <dd>藤原&nbsp;真吾</dd>

          <dt>資本金</dt>
          <dd>151,330,001円(資本準備金含む)</dd>

          <dt>顧問弁護士</dt>
          <dd>内田・鮫島法律事務所 鮫島 正洋弁護士</dd>

          <dt>顧問税理士</dt>
          <dd>N総合会計事務所</dd>

          <dt>古物商許可</dt>
          <dd>621020180338号</dd>

          <dt>電話</dt>
          <dd>{ Tel }</dd>

          <dt>メールアドレス</dt>
          <dd>info@croccha.jp</dd>
        </Dl>
        <MapWrap>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d820.3865015364134!2d135.6335361292066!3d34.66616669877773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDM5JzU4LjIiTiAxMzXCsDM4JzAyLjciRQ!5e0!3m2!1sja!2sjp!4v1620365440574!5m2!1sja!2sjp'
            width='100%'
            height='500px'
            frameBorder='0'
            allowFullScreen
          ></iframe>
        </MapWrap>
      </Section>

      {/* 代表紹介 */}
      <MemberSection>
        <div>
          <SectionTitle title='代表紹介' span='' />
          <MemberList>
            <li>
              <MemberImage src={SFujiwaraImg} alt='藤原真吾' />
              <RightContent>
                <MemberName>藤原 真吾</MemberName>
                <MemberPost>代表取締役社長 経営学修士（MBA)</MemberPost>
                <MemberDetail>
                  <dt>出身大学</dt>
                  <dd>
                    <p>
                      大阪市立大学法学部法学科
                      <br />
                      グロービス経営大学院経営研究科
                      <br />
                      経営専攻
                    </p>
                  </dd>
                  <dt>経歴</dt>
                  <dd>
                    <p>
                      外資系製薬会社にてMRとして、最優秀営業賞など数々の成果を残す。組織改革プロジェクトの実行、マーケティング戦略も経験し、2016年より関西支店長として全社戦略、エリアマーケティング、組織運営を経験。
                      <br />
                      パラレルキャリアとして兼務にて1年半ハンドメイド業界を経験後、2018年tryangle株式会社を設立し、代表取締役に就任。
                    </p>
                  </dd>
                </MemberDetail>
              </RightContent>
            </li>
          </MemberList>
        </div>
      </MemberSection>

      {/* メンバー */}
      <MemberSection>
        <div>
          <SectionTitle title='メンバー' span='' />
          <LazyLoadImage src={MembersAllImg} alt='tryangle社員一同' />
        </div>
      </MemberSection>
    </BaseLayoutTemplate>
  );
};

export default CompanyPage;

const Section = styled.section`
  padding: 3rem 0;
  text-align: center;
`;

const BgSection = styled(Section)`
  background: ${bgColor};
  padding-bottom: 100px;
`;

const H2 = styled.h2`
  color: ${mainColor};
  margin-bottom: 1rem;
  font-size: 25px;
  font-weight: normal;
  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 35px;
    height: 35px;
    background-image: url(${SymbolIcon});
  }
`;

const Vision = styled.p`
  font-size: 28px;
  @media ${breakpoint.sp} {
    font-size: 22px;
  }
`;

const ValueList = styled.ul`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  @media ${breakpoint.sp} {
    flex-direction: column;
    align-items: center;
  }
`;

const ValueItem = styled.li`
  position: relative;
  text-align: center;
  width: 200px;
  height: 200px;
  padding: 45px 0;
  ${triangleStyle};
  > p {
    font-weight: bold;
    line-height: 30px;
  }
  @media ${breakpoint.sp} {
    :nth-child(3n-1) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
`;

const Value = styled.h3`
  font-size: 25px;
  margin-bottom: 20px;
`;

const Dl = styled.dl`
  max-width: 500px;
  margin: 0 auto 1rem;
  > dt,
  > dd {
    display: inline-block;
    text-align: left;
  }
  > dt {
    width: 25%;
    font-weight: bold;
    vertical-align: top;
  }
  > dd {
    margin-bottom: 2rem;
    width: 75%;
  }
  @media ${breakpoint.sp} {
    padding: 0 1rem;
    > dt,
    dd {
      width: 100%;
    }
  }
`;

const CrocchaService = styled.span`
  margin: 8px;
`;

const MapWrap = styled.div`
  width: 100vw;
  max-width: 100vw;
`;

// メンバー
const MemberSection = styled(Section)`
  > div {
    max-width: 800px;
    margin: 0 auto;
  }

  @media ${breakpoint.tabOnly} {
    max-width: none;
    width: 100vw;
    padding: 3rem 16px;
  }

  @media ${breakpoint.sp} {
    max-width: none;
    width: 100vw;
    padding: 3rem 16px;
  }
`;

const MemberList = styled.ul`
  display: grid;
  grid-gap: 24px;
  margin: 40px auto;

  li {
    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: 32px;
    text-align: left;

    @media ${breakpoint.sp} {
      grid-template-columns: 1fr;

      > div:first-child {
        text-align: center;
      }
    }
  }
`;

const MemberImage = styled(LazyLoadImage)`
  width: 300px;
  height: 380px;
  object-fit: cover;

  @media ${breakpoint.sp} {
    width: calc(90vw - 32px);
    height: calc((90vw - 32px) * 1.2);
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  @media ${breakpoint.sp} {
    margin: 0 20px;
  }
`;

const MemberName = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const MemberPost = styled.div`
  margin: 4px 0 16px;
  color: #a2a1a1;
  font-weight: bold;

  @media ${breakpoint.sp} {
    margin: 4px 0 8px;
  }
`;

const MemberDetail = styled.dl`
  dt {
    margin-bottom: 4px;
    font-weight: bold;
  }
  dd {
    margin-bottom: 16px;
    font-size: 14px;
  }
`;
